import React from "react"
import Header from '../components/ui/header'
import Footer from '../components/ui/footer'

import { StaticQuery, graphql } from "gatsby"
import bgW from "../bg/bg_home.webp"
import bg from "../bg/DSC01544.jpeg"

export default ({children}) => (
<StaticQuery
  query={graphql`
    query LayoutFixedQuery {
      site {
        siteMetadata {
          title,
          subtitle,
          author,
          social {
            twitter,
            instagram,
            gitlab,
            strava,
          },
          description
        }
      }
    }
  `}
  render={data =>
    <>
    <div style={{
      height: '90vh' // Avoid android issue
    }} >
      <Header site={data.site}/>
      <div className="layout-content-fixed">
        {children}
      </div>
      <Footer/>
    </div>
    <picture>
      <source srcSet={bgW} type="image/webp" />
      <source srcSet={bg} type="image/jpeg" />
      <img
        alt="Alt Text!"
        style={{
          position: 'fixed',
          width: '100vw',
          height: '100vh',
          objectFit: 'cover',
          zIndex: '-1',
          top: 0,
          bottom: 0,
          opacity: '0.4'}}
          src={bg} alt="Logo" />
      </picture>
    </>
  }
  />
)
